<template>
  <MitchOS
    :taskGroupBindings="['system']"
    :class="{
        'is-user-activated': isUserActivated,
        'is-obs': obsStudioPluginVersion,
        'is-playing': isScreensharePlaying,
      }"
  >
    <!-- WALLPAPER -->
    <WallpaperIcons
      class="wallpaper-icons"
      :isPlaying="isUserActivated"
    />

    <div
      class="screen-share-parent"
      @click="handleFirstTimeActivate"
    >
      <!-- TEXT -->
      <div
        v-if="!obsStudioPluginVersion"
        class="text-box text-center"
      >
        <h2 class="mb-0">{{ statusText }}</h2>
        <span
          v-if="isUserActivated"
          class="d-block"
        >
          <i class="fa fa-user"></i> <b>id:</b>
          {{ clientIdFormatted }}</span>

        <!-- <small>OBS studio plugin version: {{ obsStudioPluginVersion }}</small> -->
      </div>

      <div class="media-device-monitor">
        <video
          ref="videoEl"
          x-webkit-airplay="allow"
          class="video"
        >
        </video>

      </div>
    </div>
  </MitchOS>
</template>

<script>
  /**
   * NOTE: This screen is redundant since it's now a dedicated component in /programs.
   * Kept around just to test with insiders, due to the click to activate interface.
   */
  import { mapGetters } from "vuex";
  import Peer from "peerjs";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";
  import WallpaperIcons from "../../components/MitchOS/Backdrops/WallpaperIcons/WallpaperIcons.vue";

  // const isDev = process.env.NODE_ENV !== "production";
  // const host = isDev ? "localhost" : process.env.VUE_APP_SERVER_ORIGIN;
  const host = "server.themitchinghour.com";

  export default {
    name: "broadcast-peer",
    components: {
      MitchOS,
      WallpaperIcons,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        isScreensharePlaying: false,
        isUserActivated: false,
        obsStudioPluginVersion: null,
        peerInstance: null,
        videoEl: null,
        statusText: "Click to activate",
      };
    },
    computed: {
      ...mapGetters({
        getClientDeviceId: "user/getClientDeviceId",
      }),
      clientIdFormatted() {
        return this.getClientDeviceId?.replace(/[_-]/g, "");
      },
    },
    watch: {
      getClientDeviceId(newVal, oldVal) {
        if (newVal) {
          this.videoEl = this.$refs.videoEl;
          this.setupPeer();
        }
      },
    },
    created() {
      // https://github.com/obsproject/obs-browser/blob/master/README.md
      this.obsStudioPluginVersion = window.obsstudio?.pluginVersion;

      // OBS automatically creates a window interaction in the browser source.
      if (this.obsStudioPluginVersion) {
        this.isUserActivated = true;
        this.statusText = "Waiting for connection...";
      }

      this.$nextTick(() => {
        document.documentElement.style.background = "transparent";
      });
    },
    methods: {
      handleFirstTimeActivate() {
        this.isUserActivated = true;
        this.statusText = "One moment...";
      },
      setupPeer() {
        this.peerInstance = new Peer(this.clientIdFormatted, {
          host,
          path: "/peerjs",
          secure: true,
        });

        // ON PEER ERROR
        this.peerInstance.on("error", (error) => {
          console.error(error);
        });

        // ON CONN
        this.peerInstance.on("connection", (connection) => {
          console.log("Incoming peer connection!");
          this.statusText = "Ready to receive...";

          // ON CONN DATA
          connection.on("data", (data) => {
            console.log(`Received: ${data}`);
          });

          // ON CONN DATA
          connection.on("open", (id) => {
            connection.send("Connection open! Yay!:", id);
          });

          // ON CONN CLOSE
          connection.on("close", (woo) => {
            // console.log("Detected close from transmitter.");
            // this.peerInstance.disconnect();
            this.statusText = "Waiting to receive...";

            if (this.videoEl) {
              this.videoEl.srcObject = null;
              this.isScreensharePlaying = false;
            }
          });
        });

        // ON CALL
        this.peerInstance.on("call", (call) => {
          console.log({ call });

          const stream = this.createMediaStream();
          call.answer(stream); // Answer the call with an A/V stream.

          call.on("stream", (mediaStream) => {
            console.log({ success: mediaStream });
            this.videoEl.srcObject = mediaStream;
            this.videoEl.play();
            this.isScreensharePlaying = true;
          });
        });
      },

      createMediaStream() {
        // issue: https://github.com/peers/peerjs/issues/158

        const createEmptyAudioTrack = () => {
          const ctx = new AudioContext();
          const oscillator = ctx.createOscillator();
          const dst = oscillator.connect(ctx.createMediaStreamDestination());
          oscillator.start();
          const track = dst.stream.getAudioTracks()[0];
          return Object.assign(track, { enabled: false });
        };

        const createEmptyVideoTrack = ({ width, height }) => {
          const canvas = Object.assign(document.createElement("canvas"), { width, height });
          canvas.getContext("2d").fillRect(0, 0, width, height);
          const stream = canvas.captureStream();
          const track = stream.getVideoTracks()[0];
          return Object.assign(track, { enabled: false });
        };

        return new MediaStream([
          createEmptyAudioTrack(),
          createEmptyVideoTrack({ width: 640, height: 480 }),
        ]);

        // const CONSTRAINTS = {
        //   // audio: false,
        //   video: {
        //     aspectRatio: 1.777777778,
        //     frameRate: { ideal: 25, max: 25 },
        //     quality: 10,
        //     width: { min: 1280, ideal: 1920, max: 1920 },
        //     height: { min: 720, ideal: 1080 },
        //   },
        // };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .is-obs {
    visibility: hidden;
  }

  .is-obs.is-playing {
    visibility: visible;
  }

  .screen-share-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #3a3a3a;
    height: 100%;
    color: darken(white, 70%);
    // border: 5px dashed orange;
    z-index: 1;
  }

  .wallpaper-icons {
    filter: brightness(30%);
  }

  .is-user-activated .wallpaper-icons {
    filter: none;
  }

  .is-user-activated .screen-share-parent {
    // background: #09ff7f;
    color: #222;
  }

  .is-obs .screen-share-parent {
    background: none;
  }

  .text-box {
    padding: rem-calc(15);
    border-radius: 10px;
    background: rgba(black, 0.2);
    backdrop-filter: blur(3px);
  }

  .is-user-activated .text-box {
    color: rgba(white, 0.8);
  }

  .media-device-monitor {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: calc(100% - #{rem-calc(420)});
    height: calc(100% - #{rem-calc(120)});
    padding: rem-calc(65);
    border-radius: 10px;
    pointer-events: none;

    video {
      max-height: 100%;
      border-radius: 10px;
    }
  }

  .is-playing .media-device-monitor video {
    box-shadow: 0 5px 20px 15px rgba(black, 0.2);
  }
</style>
